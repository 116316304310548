<template>
  <div :class="[fullWidth ? 'nk-footer nk-auth-footer-full' : 'nk-auth-footer']">
    <div :class="[fullWidth ? 'container wide-lg' : 'nk-block-between']">
      <div :class="fullWidth ? 'row g-3' : ''">
        <div :class="fullWidth ? 'col-lg-6 order-lg-last' : ''">
          <ul class="nav nav-sm" :class="fullWidth ? 'justify-content-center justify-content-lg-end' : ''">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name: 'TOS'}">{{$t('Terms & Condition')}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name: 'PrivacyPolicy'}">{{$t('Privacy Policy')}}</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">{{$t('Help')}}</a>
            </li>
            <li class="nav-item dropup">
              <a class="dropdown-toggle dropdown-indicator has-indicator nav-link text-primary" data-toggle="dropdown" data-offset="0,10">
                <small v-if="store.state.appConfig.lang === 'en'">{{$t('English')}}</small>
                <small v-else-if="store.state.appConfig.lang === 'nl'">Dutch</small>
              </a>
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul class="language-list">
                  <li>
                    <a href="javascript:;" class="language-item" v-on:click="changeLang('en')">
                      <img :src="require('@/assets/images/flags/english.png')" alt="" class="language-flag">
                      <span class="language-name">{{$t('English')}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="language-item" v-on:click="changeLang('nl')">
                      <img :src="require('@/assets/images/flags/netherland.png')" alt="" class="language-flag">
                      <span class="language-name">Dutch</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-6" v-if="fullWidth">
          <div class="nk-block-content text-center text-lg-left">
            <p class="text-soft">&copy; {{ new Date().getFullYear() }} Hypotheek.io. {{$t('All Rights Reserved')}}.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="!fullWidth">
      <p>&copy; {{ new Date().getFullYear() }} Hypotheek.io. {{$t('All Rights Reserved')}}.</p>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import "bootstrap"
import i18n from "@/libs/i18n"
export default {
  props: {
    fullWidth: Boolean,
  },
  setup(){

    const changeLang = (lang) => {
      store.dispatch('appConfig/change_language', lang)
      .then(()=> i18n.global.locale = lang)
    }

    return {
      changeLang,
      store,
    }
  }
}
</script>
