<template>
  <page-template>
    <div class="nk-split nk-split-page nk-split-md">
      <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div class="nk-block nk-block-middle nk-auth-body">
          <div class="brand-logo pb-5" v-if="!$isHybridPlatform">
            <brand-logo></brand-logo>
          </div>
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title">{{ $t('Reset password')}}</h5>
              <div class="nk-block-des">
                <p v-if="!$route.params.reset_token">{{$t('forgot_password.reset_message')}}</p>
                <p v-else>{{$t('forgot_password.new_pwd_message')}}</p>
              </div>
            </div>
          </div>
          <div v-if="!$route.params.reset_token">
            <form v-on:submit.prevent="pwdResetRequest()" class="pb-4">
              <form-group>
                <div class="form-label-group">
                  <label class="form-label" for="default-01">{{ $t('Email')}}</label>
                </div>
                <input type="text" class="form-control form-control-lg" id="default-01" :placeholder="$t('login_page.enter_email')" v-model="user_email">
              </form-group>
              <form-group>
                <form-submit-button :text="$t('forgot_password.button_text')"></form-submit-button>
              </form-group>
            </form>
          </div>
          <div v-else>
            <form v-on:submit.prevent="resetPassword()" class="pb-4">
              <form-group>
                <label class="form-label" for="n_p_1">{{ $t('Password') }}</label>
                <div class="form-control-wrap">
                  <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="new_password.pass_visible= !new_password.pass_visible" tabindex="-1">
                    <nio-icon icon="ni-eye" v-if="!new_password.pass_visible"></nio-icon>
                    <nio-icon icon="ni-eye-off" v-else></nio-icon>
                  </a>
                  <input :type="new_password.pass_visible ? 'text': 'password'"
                         class="form-control form-control-lg"
                         id="n_p_1" placeholder="********"
                         v-model="new_password.password"
                         autocomplete="off"/>
                </div>
              </form-group>
              <form-group>
                <label class="form-label" for="r_password">{{ $t('Repeat password') }}</label>
                <div class="form-control-wrap">
                  <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="new_password.confirm_pass_visible= !new_password.confirm_pass_visible" tabindex="-1">
                    <nio-icon icon="ni-eye" v-if="!new_password.confirm_pass_visible"></nio-icon>
                    <nio-icon icon="ni-eye-off" v-else></nio-icon>
                  </a>
                  <input :type="new_password.confirm_pass_visible ? 'text': 'password'"
                         class="form-control form-control-lg"
                         id="r_password"
                         placeholder="********"
                         v-model="new_password.confirm"
                         autocomplete="off"/>
                </div>
              </form-group>
              <form-group>
                <form-submit-button :text="$t('Submit')"></form-submit-button>
              </form-group>
            </form>
          </div>
          <div class="form-note-s2 pt-2">
            <router-link :to="{name:'Login'}" class="fw-bold">{{$t('forgot_password.return_btn')}}</router-link>
          </div>
        </div>
        <auth-footer></auth-footer>
      </div>
    </div>
  </page-template>
</template>

<script>
import {loadingController, toastController, alertController, isPlatform} from "@ionic/vue"
import { BrandLogo } from "@core/components"
import { FormGroup } from "@core/layouts"
import FormSubmitButton from "@/views/auth/components/FormSubmitButton";
import {useStore} from "vuex";
import { useRoute, useRouter } from "vue-router"
import {ref, defineComponent, reactive} from "vue";
import axios from "@/libs/axios"
import AuthFooter from "@/views/auth/components/AuthFooter";
import {useI18n} from "vue-i18n";
import useCommonFunc from "@core/comp-functions/common"
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {PageTemplate, AuthFooter, FormSubmitButton, BrandLogo, FormGroup},
  setup(){

    const store = useStore()
    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    let user_email = ref('')
    let new_password = reactive({
      password: '',
      confirm: '',
      pass_visible: false,
      confirm_pass_visible: false,
    })

    const route = useRoute()
    const router = useRouter()
    const { changeLang, passwordValidationRegex } = useCommonFunc()

    // Request password reset link
    const pwdResetRequest = async () => {

      let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})
      if(!user_email.value.length){
        toast.message = i18n.t('forgot_password.empty_email')
        await toast.present()
        return false
      }

      let loader = await loadingController.create({message: i18n.t('Please wait...')})
      await loader.present()

      axios.post('/api/password/request_reset', {email: user_email.value, locale: store.state.appConfig.lang})
      .then(r=>{
        if(!r.data.success){
          toast.message = r.data.message
          toast.present()
        }
        else{
          user_email.value = ''
          alertController.create({
            header:'Link sent', message: i18n.t('forgot_password.link_sent_message'),
            cssClass: isDesktop ? 'alert-web' : '',
            buttons:[
              {
                text: 'OK',
                cssClass: isDesktop ? 'alert-btn-light' : '',
              }
            ]
          }).then((a) => a.present())
        }
      })
      .catch(er=>{
        toast.message = er.response ? er.response.status+' '+er.response.statusText : er.toString()
        toast.present()
      })
      .then(() => loader.dismiss())
    }
    const resetPassword = async () => {

      let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})
      if(!passwordValidationRegex.test(new_password.password)){
        toast.message = i18n.t('invalid_password')
        await toast.present()
        return false
      }

      let loader = await loadingController.create({message: i18n.t('Please wait...')})
      await loader.present()

      axios.post('/api/password/reset', {password: new_password.password, token: route.params.reset_token, locale: store.state.appConfig.lang})
      .then(r=>{
        if(!r.data.success){
          toast.message = r.data.message
          toast.present()
        }
        else{
          new_password.password = new_password.confirm = ''
          alertController.create({
            header:'Password Changed', message: i18n.t('forgot_password.pwd_changed_message'),
            cssClass: isDesktop ? 'alert-web' : '',
            buttons:[
              {
                text: i18n.t('OK'),
                cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
              },
              {
                text: i18n.t('Login'),
                cssClass: isDesktop ? 'alert-btn-primary' : '',
                handler: ()=>{
                  router.push({name: 'Login'})
                }
              }
            ]
          }).then((a) => a.present())
        }
      })
      .catch(er=>{
        toast.message = er.response ? er.response.status+' '+er.response.statusText : er.toString()
        toast.present()
      })
      .then(() => loader.dismiss())
    }

    return {
      changeLang,
      new_password,
      pwdResetRequest,
      resetPassword,
      router,
      user_email,
    }
  },
})
</script>
